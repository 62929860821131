import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const copyText = (text: string) => {
    try {
        navigator.clipboard.writeText(text);
    } catch {
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = text;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
    }
}

export const getTG = () => {
    // @ts-ignore
    return window.Telegram.WebApp;
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const getUnixTime = () => {
    return parseInt(String(new Date().getTime() / 1000));
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getSocialIconByUrl = (url: string) => {
    if (url.includes('x.com') || url.includes('twitter.com')) {
        return 'social/twitter.png'
    }

    if (url.includes('t.me')) {
        return 'social/telegram.png'
    }

    if (url.includes('facebook.com')) {
        return 'social/facebook.png'
    }

    return 'emoji/loudspeaker.png';
}

export const getBonusTypes = (task: any) => {
    const output = []
    if (task.goldReward) output.push({ type: 'gold', value: task.goldReward})
    if (task.diamondsReward) output.push({ type: 'crystals', value: task.diamondsReward})
    if (task.ttcReward) output.push({ type: 'ttc', value: task.ttcReward})
    return output
}

export const getTimeLeft = (to: number, includes: 'hours' | 'minutes') => {
    const now = dayjs().unix()
    const timeLeft = Math.abs(to - now)
    const hours = Math.floor(timeLeft / (60*60))
    const mins = Math.floor(timeLeft / (60) % 60)
    const secs = Math.floor(timeLeft % 60)
    return (includes === 'hours' ? `${hours}:${mins < 10 ? "0" : ""}` : '') + `${mins}:${secs < 10 ? "0" : ""}${secs}`
}

export const getLongTimeLeft = (to: string, dayLocale: string) => {
    const now = dayjs().unix()
    const until = dayjs(to).unix()

    const timeLeft = Math.abs(until - now)
    const days = Math.floor(timeLeft / (60 * 60 * 24))
    const hours = Math.floor(timeLeft / (60*60) % 24)
    const mins = Math.floor(timeLeft / (60) % 60)
    const secs = Math.floor(timeLeft % 60)
    return `${days !== 0 ? (days + dayLocale + ' • ') : ''}${hours < 10 ? '0' : ''}${hours} : ${mins < 10 ? "0" : ""}${mins} : ${secs < 10 ? "0" : ""}${secs}`
}

export const boostsServerToFrontId: { [key: string]: string} = {
    'multiclick': 'tap',
    'energy': 'energyUp',
    'recharging': 'energyBoost',
    'diamonds_exchange_10_hours': 'exchange10',
    'diamonds_exchange_24_hours': 'exchange24'
}

export const boostsFrontToServerId: { [key: string]: string} = {
    'tap': 'multiclick',
    'energyUp': 'energy',
    'energyBoost': 'recharging',
    'exchange10': 'diamonds_exchange_10_hours',
    'exchange24': 'diamonds_exchange_24_hours'
}

export const achievementsServerToFrontId: { [key: string]: string} = {
    'BUILDINGS_COUNT': 'building',
    'TOTAL_BUILDINGS_COUNT': 'total',
    'CLICKS_COUNT': 'tap',
    'TOTAL_IMPROVEMENTS_COUNT': 'improv',
    'FRIENDS_COUNT': 'friends'
}

export const BONUSES: any = [
    { id: 'iron', name: 'Iron', gold: 10, premium: 20 },
    { id: 'bronze', name: 'Bronze', gold: 2, premium: 4},
    { id: 'silver', name: 'Silver', gold: 3, premium: 6 },
    { id: 'gold', name: 'Gold', gold: 5, premium: 10 },
    { id: 'platinum', name: 'Platinum', gold: 8, premium: 16 },
    { id: 'diamond', name: 'Diamond', gold: 12, premium: 24 },
    { id: 'master', name: 'Master', gold: 20, premium: 40 }
];

export const taskMapCodes: {[key:string]: {type: string, action: string | null}} = {
    'subscribe_bo_cryptobunny_chat' : {
        type: 'telegram',
        action: 'group'
    },
    'subscribe_bo_cryptobunny_channel': {
        type: 'telegram',
        action: 'channel'
    },
    'bunny_whitepaper': {
        type: 'notion',
        action: null
    },
    'cryptobot': {
        type: 'cryptobot',
        action: null
    },
    'youtube': {
        type: 'youtube',
        action: null
    },
    'discord': {
        type: 'discord',
        action: null
    },
    'twitter': {
        type: 'twitter',
        action: null
    },
    'facebook': {
        type: 'facebook',
        action: null
    },
    'fallback': {
        type: 'fallback',
        action: null
    }
}

export const donations = [
    { id: 'one', amount: 20, price: 1, discount: null, original: null, pack: 'xsmall', status: null },
    { id: 'two', amount: 150, price: 5, discount: 33, original: 7.5, pack: 'small', status: 'pop' },
    { id: 'cup', amount: 1200, price: 30, discount: 50, original: 60, pack: 'medium', status: null },
    { id: 'basket', amount: 6000, price: 120, discount: 60, original: 300, pack: 'large', status: null },
    { id: 'chest', amount: 12000, price: 150, discount: 75, original: 600, pack: 'xlarge', status: 'fav' }
]

export const premiumOptions = [
    { id: 'days_3', amount: 3, price: 300, discount: null, original: null },
    { id: 'days_7', amount: 7, price: 600, discount: 14, original: 700 },
    { id: 'days_14', amount: 14, price: 1000, discount: 29, original: 1400 }
]