import { BuildingItem } from "../panels/Buildings/Buildings"
import { ImprovementItem } from "../panels/Buildings/Buildings"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export type LevelType = 'wood'|
    'iron' |
    'bronze'|
    'silver'|
    'gold'|
    'platinum'|
    'diamond'|
    'master'

export interface DefaultStateType {
    screenPopup: null | 'connection' | 'unSupportPlatform' | 'manyConnections'
    activeModal: null | string
    activeModalParams: null | string | number | object

    activeSnackbar: null | string
    activeSnackbarParams: null | string | number | object

    level: null | LevelType
    gold: null | number
    usdt: null | number
    ttc: null | number
    premium: {
        active: boolean
        until: string
    } | null
    lastAdWatch: null
    exchangeRate: null | number
    autoclickEndTime: null | number
    rocket: null | number
    energyLeft: null | number
    dailyEnergy: null | number
    rechargingEnergyPerSecond: null | number
    goldPerClick: null | number
    goldPerHour: null | number
    crystals: null | number
    buildings: { [id: number | string] : BuildingItem } | null
    limitedBuildings: { [id: number | string] : BuildingItem } | null
    premiumBuildings: { [id: number | string] : BuildingItem } | null
    improvements: { 
        available: {[id: number]: ImprovementItem}
        bought: {[id: number]: ImprovementItem}
    } | null

    prizepool: {
        [key: string] : number
    } | null
    prizepoolWinners: {
        [key: string] : Array<{
            photo: string
            name: string
            tg_id: number
            reward: number
            premium: boolean
        }>
    } | null
    myPosition: {
        id: string
        league: LevelType
        position: number | string
        profit: number
        name: string
        photo: string | null
    } | null
    friendsCount: number
    friends : {
        name: string
        photo: string | null
        crystals: number
        level: LevelType
        gold: number
        premium: boolean
    }[] | null
    levels: {
        [key: string] : {
            level: number
            levelName: 'wood' | 'iron' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'master'
            min: number
            max: number
        }
    } | null
    idToLevelMap: {
        [key: string] : 'wood' | 'iron' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'master'
    } | null

    dailies: {
        [key: number] : {
            profit: number
            unlockedAt: number
            status: string
            rewardLevel: number
        }
    } | null

    currentDaily: {
        profit: number
        unlockedAt: number
        available: boolean
        rewardLevel: number
    } | null

    achievementsList: {
        [key: string]: {
            id: string
            level: number
            type: 'building' | 'tap' | 'total' | 'profit' | 'friends' | 'improv'
            innerId: number
            unlocked: boolean
            target: number
        }
    } | null

    boosts: { 
        [key: string]: {
            id: string
            name: string
            description: string
            price: number
            isPremium: boolean
            level: number
            effect: string
    }} | null

    freezeEnergy: {
        run: boolean
        until: number
    }

    tasks: {
        [key: string] : {
        id: number
        type: string
        action: string
        bonus: number
        bonuses: { type: string, value: number }[] | null
        startsFrom: number | null
        endsAt: number | null
        completed: boolean
        link: string
        imageLink: string | null
        dbType: string
        locale: {
            title: string | null
            description: string | null
        }

    }} | null

    inviteLink: string | null
    emission: number | null
    stage: number | null
    tonRate: number
    xtrRate: number
    cbcAddress: string | null
    cbcAddressTimeout: number | null

    miners: {
        id: string
        total: number
        price: number
    }[] | null

    activeMiners: {
        [key: string] : {
            uid: string
            id: string
            total: number
            price: number
            expiredAt: number
            progress: number
            status: string
        }
    } | null

    walletAddress: string | null
}

const defaultState: DefaultStateType = {
    screenPopup: 'connection',
    activeModal: null,
    activeModalParams: null,
    activeSnackbar: null,
    activeSnackbarParams: null,
    walletAddress: null,

    level: null,
    gold: null,
    usdt: null,
    premium: null,
    lastAdWatch: null,
    ttc: null,
    exchangeRate: 0.05,
    autoclickEndTime: null,
    rocket: null,
    energyLeft: null,
    dailyEnergy: null,
    rechargingEnergyPerSecond: null,
    goldPerClick: null,
    goldPerHour: null,
    crystals: null,
    friends: null,
    friendsCount: 0,
    buildings: null,
    limitedBuildings: null,
    premiumBuildings: null,
    improvements: null,
    prizepool: null,
    prizepoolWinners: null,
    myPosition: null,

    levels: null,
    idToLevelMap: null,
    tasks: null,

    boosts: null,
    dailies: null,
    currentDaily: null,
    freezeEnergy: {
        run: false,
        until: 0
    },
    inviteLink: null,
    emission: null,
    stage: 4,
    tonRate: 6,
    xtrRate: 0.2,
    cbcAddress: null,
    cbcAddressTimeout: null,

    miners: null,
    activeMiners: null,
    achievementsList: null
};

export type ActionType = {
    type: string
    payload: any
}

export const SET_SCREEN_POPUP = "SET_SCREEN_POPUP";
export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL";
export const SET_ACTIVE_MODAL_PARAMS = "SET_ACTIVE_MODAL_PARAMS";

export const SET_ACTIVE_SNACKBAR = "SET_ACTIVE_SNACKBAR";
export const SET_ACTIVE_SNACKBAR_PARAMS = "SET_ACTIVE_SNACKBAR_PARAMS";

export const SET_INVITE_LINK = "SET_INVITE_LINK";
export const SET_EMISSION = "SET_EMISSION";
export const SET_STAGE = "SET_STAGE";
export const SET_AD_TIMER = "SET_AD_TIMER";
export const SET_FRIENDS = "SET_FRIENDS";
export const SET_FRIENDS_COUNT = "SET_FRIENDS_COUNT";
export const ADD_FRIENDS = "ADD_FRIENDS";
export const SET_PREMIUM = "SET_PREMIUM"

export const SET_ACHIEVEMENTS = "SET_ACHIEVEMENTS"
export const UPDATE_ACHIEVEMENT = "UPDATE_ACHIEVEMENT"

export const SET_LEVEL = "SET_LEVEL";
export const SET_GOLD = "SET_GOLD";
export const SET_USDT = "SET_USDT";
export const SET_TASKS = "SET_TASKS";
export const SET_TTC = "SET_TTC";
export const ADD_TTC = "ADD_TTC";
export const SET_RATE = "SET_RATE";
export const ADD_CRYSTALS = "ADD_CRYSTALS";
export const SET_CRYSTALS = "SET_CRYSTALS";
export const ADD_GOLD = "ADD_GOLD";
export const REDUCE_CRYSTALS = "REDUCE_CRYSTALS";
export const REDUCE_GOLD = "REDUCE_GOLD";
export const SET_ROCKET = "SET_ROCKET";
export const ADD_ROCKET = "ADD_ROCKET";
export const REDUCE_ROCKET = "REDUCE_ROCKET";
export const SET_ENERGY_LEFT = "SET_ENERGY_LEFT";
export const SET_DAILY_ENERGY = "SET_DAILY_ENERGY";
export const ADD_ENERGY_LEFT = "ADD_ENERGY_LEFT";
export const REDUCE_ENERGY_LEFT = "REDUCE_ENERGY_LEFT";
export const SET_GOLD_PER_CLICK = "SET_GOLD_PER_CLICK";
export const SET_GOLD_PER_HOUR = "SET_GOLD_PER_HOUR";
export const SET_AUTOCLICK_ENT_TIME = "SET_AUTOCLICK_ENT_TIME";
export const SET_RECHARGING_ENERGY_PER_SECOND = "SET_RECHARGING_ENERGY_PER_SECOND";
export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS"

export const SET_BUILDINGS = "SET_BUILDINGS";
export const SET_LIMITED_BUILDINGS = "SET_LIMITED_BUILDINGS";
export const SET_PREMIUM_BUILDINGS = "SET_PREMIUM_BUILDINGS";
export const SET_IMPROVEMENTS = "SET_IMPROVEMENTS";
export const MOVE_IMPROVEMENT = "MOVE_IMPROVEMENT";
export const SET_PRIZEPOOL = "SET_PRIZEPOOL";
export const SET_PRIZEPOOL_WINNERS = "SET_PRIZEPOOL_WINNERS";
export const SET_MY_POSITION = "SET_MY_POSITION";
export const SET_TON = "SET_TON"
export const SET_XTR = "SET_XTR"
export const SET_CBC_ADDRESS = "SET_CBC_ADDRESS"
export const SET_CBC_TIMEOUT = "SET_CBC_TIMEOUT"

export const SET_LEVELS = "SET_LEVELS"
export const SET_BOOSTS = "SET_BOOSTS"
export const SET_DAILIES = "SET_DAILIES"
export const SET_CURRENT_DAILY = "SET_CURRENT_DAILY"
export const RESET_CURRENT_DAILY = "RESET_CURRENT_DAILY"
export const SET_FREEZE = "SET_FREEZE"
export const UPDATE_BOOST = "UPDATE_BOOST"

export const SET_MINERS = "SET_MINERS"
export const SET_ACTIVE_MINERS = "SET_ACTIVE_MINERS"
export const UPDATE_ACTIVE_MINERS = "UPDATE_MINERS"

export const reducer = (state = defaultState, action: ActionType) => {
    const payload = action.payload;

    switch (action.type) {
        
        case SET_SCREEN_POPUP:
            return {...state, screenPopup: payload};

        case SET_ACTIVE_MODAL:
            return {...state, activeModal: payload};

        case SET_ACTIVE_MODAL_PARAMS:
            return {...state, activeModalParams: payload};

        case SET_ACTIVE_SNACKBAR:
            return {...state, activeSnackbar: payload};

        case SET_ACTIVE_SNACKBAR_PARAMS:
            return {...state, activeSnackbarParams: payload};

        case SET_LEVEL:
            return {...state, level: payload};

        case SET_GOLD:
            return {...state, gold: payload};

        case SET_USDT:
            return {...state, usdt: payload};

        case SET_TASKS:
            return {...state, tasks: payload};

        case SET_TTC:
            return {...state, ttc: Number(payload ?? 0)};

        case SET_PREMIUM:
            return {...state, premium: payload};

        case SET_WALLET_ADDRESS:
            return {...state, walletAddress: payload}

        case ADD_TTC:
            if (state.ttc === null) {
                return {...state};
            }

            return {...state, ttc: Number(state.ttc) + Number(payload)};

        case SET_RATE:
            return {...state, exchangeRate: payload};

        case SET_AD_TIMER:
            return {...state, lastAdWatch: payload};

        case SET_CRYSTALS:
            return {...state, crystals: payload};

        case ADD_CRYSTALS:
            if (state.crystals === null) {
                return {...state};
            }

        return {...state, crystals: state.crystals + Number(payload)};
    
        case SET_FRIENDS:
            return {...state, friends: payload};

        case SET_CBC_ADDRESS:
            return {...state, cbcAddress: payload}

        case SET_CBC_TIMEOUT:
            return {...state, cbcAddressTimeout: payload}

        case SET_FRIENDS_COUNT: 
            return {...state, friendsCount: payload}

        case ADD_FRIENDS:
            if (!state.friends) return {...state}
            return {...state, friends: [...state.friends, ...payload]}

        case REDUCE_CRYSTALS:
            if (state.crystals === null) {
                return {...state};
            }

            return {...state, crystals: state.crystals - Number(payload)};

        case ADD_GOLD:
            if (state.gold === null) {
                return {...state};
            }

            return {...state, gold: state.gold + Number(payload)};

        case REDUCE_GOLD:
            if (state.gold === null) {
                return {...state};
            }

            return {...state, gold: state.gold - Number(payload)};

        case SET_ROCKET:
            return {...state, rocket: payload};

        case ADD_ROCKET:
            if (state.rocket === null) {
                return {...state};
            }

            return {...state, rocket: state.rocket + Number(payload)};

        case REDUCE_ROCKET:
            if (state.rocket === null) {
                return {...state};
            }

            return {...state, rocket: state.rocket - Number(payload)};

        case SET_ENERGY_LEFT:
            return {...state, energyLeft: payload};

        case SET_DAILY_ENERGY:
            return {...state, dailyEnergy: payload};

        case SET_AUTOCLICK_ENT_TIME:
            return {...state, autoclickEndTime: payload};

        case ADD_ENERGY_LEFT: 
            if (state.energyLeft === null 
                || state.dailyEnergy === null 
                || state.energyLeft >= state.dailyEnergy
                || state.rechargingEnergyPerSecond === null)
            {
                return {...state}
            }

            return {...state, energyLeft: (state.energyLeft + state.rechargingEnergyPerSecond > state.dailyEnergy) ? state.dailyEnergy : state.energyLeft + state.rechargingEnergyPerSecond}; 

        case REDUCE_ENERGY_LEFT:
            if (state.energyLeft === null) {
                return {...state};
            }

            return {...state, energyLeft: state.energyLeft - payload};

        case SET_GOLD_PER_CLICK:
            return {...state, goldPerClick: payload};

        case SET_GOLD_PER_HOUR:
            return {...state, goldPerHour: payload};

        case SET_RECHARGING_ENERGY_PER_SECOND:
            return {...state, rechargingEnergyPerSecond: payload};
        
        case SET_TON: 
            return {...state, tonRate: payload}

        case SET_XTR: 
            return {...state, xtrRate: payload}

        case SET_BUILDINGS:
            return {...state, buildings: payload}

        case SET_PREMIUM_BUILDINGS:
            return {...state, premiumBuildings: payload}

        case SET_LIMITED_BUILDINGS:
            return {...state, limitedBuildings: payload}

        case SET_ACHIEVEMENTS:
            return {...state, achievementsList: payload}

        case UPDATE_ACHIEVEMENT:
            if (payload && state.achievementsList && payload in state.achievementsList) {
                return { ...state, achievementsList: { ...state.achievementsList, [payload] : {
                    ...state.achievementsList[payload],
                    unlocked: true
                }}, goldPerHour: (state.goldPerHour ?? 0) * 1.05 }
            }
            return { ...state }

        case SET_IMPROVEMENTS: {
            const newBuildings = state.buildings ? { ...state.buildings} : null
            const newLimited = state.limitedBuildings ? { ...state.limitedBuildings} : null
            const newPremium = state.premiumBuildings ? { ...state.premiumBuildings} : null
            if (payload.bought) {
                for (const boughtItem of Object.values(payload.bought) as ImprovementItem[]) {
                    if (newBuildings && boughtItem.buildingId in newBuildings && newBuildings[boughtItem.buildingId].purchased >= boughtItem.buildingCount) {
                        newBuildings[boughtItem.buildingId].profitRate *= 1 + (boughtItem.scalePercent / 100)
                    }
                    if (newLimited && boughtItem.buildingId in newLimited && newLimited[boughtItem.buildingId].purchased >= boughtItem.buildingCount) {
                        newLimited[boughtItem.buildingId].profitRate *= 1 + (boughtItem.scalePercent / 100)
                    }
                    if (newPremium && boughtItem.buildingId in newPremium && newPremium[boughtItem.buildingId].purchased >= boughtItem.buildingCount) {
                        newPremium[boughtItem.buildingId].profitRate *= 1 + (boughtItem.scalePercent / 100)
                    }
                }
            }
                
            return {...state, buildings: newBuildings, limitedBuildings: newLimited, premiumBuildings: newPremium, improvements: payload}
        }

        case MOVE_IMPROVEMENT: {
            const oldImprovements = { ...state.improvements }
            if (oldImprovements && oldImprovements.available && oldImprovements.bought && payload in oldImprovements.available) {
                const moveElem = { ...oldImprovements.available[payload]}
                delete oldImprovements.available[payload]
                oldImprovements.bought[payload] = { ...moveElem, status: 'bought' }

                const newBuildings = state.buildings ? { ...state.buildings} : null
                const newLimited = state.limitedBuildings ? { ...state.limitedBuildings} : null
                const newPremium = state.premiumBuildings ? { ...state.premiumBuildings} : null

                if (newBuildings && moveElem.scalePercent && moveElem.buildingId in newBuildings) {
                    newBuildings[moveElem.buildingId].profitRate = newBuildings[moveElem.buildingId].profitRate * (1 + (moveElem.scalePercent / 100))
                }
                if (newLimited && moveElem.scalePercent && moveElem.buildingId in newLimited) {
                    newLimited[moveElem.buildingId].profitRate = newLimited[moveElem.buildingId].profitRate * (1 + (moveElem.scalePercent / 100))
                }
                if (newPremium && moveElem.scalePercent && moveElem.buildingId in newPremium) {
                    newPremium[moveElem.buildingId].profitRate = newPremium[moveElem.buildingId].profitRate * (1 + (moveElem.scalePercent / 100))
                }

                return { ...state, buildings: newBuildings, limitedBuildings: newLimited, premiumBuildings: newPremium, improvements: { ... oldImprovements}}
            }
            return state;
        }

        case SET_PRIZEPOOL: {
            return {...state, prizepool: {...payload}}
        }

        case SET_MY_POSITION: {
            return {...state, myPosition: {...payload}}
        }

        case SET_PRIZEPOOL_WINNERS: {
            return {...state, prizepoolWinners: {...payload}}
        }

        case SET_LEVELS: {
            return {...state,
                levels: payload.reduce((res: any, item: any) => ({ ...res, [item.levelName]: item}), {}),
                idToLevelMap: payload.reduce((res: any, item: any) => ({ ...res, [item.level]: item.levelName}), {}),
            }
        }

        case SET_BOOSTS: {
            return {...state, boosts: payload}
        }

        case SET_INVITE_LINK: {
            return {...state, inviteLink: payload}
        }

        case SET_EMISSION: {
            return {...state, emission: payload}
        }

        case SET_STAGE: {
            return {...state, stage: payload}
        }

        case SET_MINERS: 
            return {...state, miners: payload}
        
        case SET_ACTIVE_MINERS:
            return {...state, activeMiners: payload}

        case UPDATE_ACTIVE_MINERS: {
            switch (payload.type) {
                case "ADD": return {...state, activeMiners: {...state.activeMiners, [payload.id] : payload.data }}
                case "PROGRESS": {
                    const miners = {...state.activeMiners}
                    if (!miners) return
                    for (const item of payload.data) {
                        if (item.uid in miners) {
                            miners[item.uid] = {...item}
                        }
                    }
                    return {...state, activeMiners: miners}
                }
                default: return state
            }
        }

        case UPDATE_BOOST: {
            return {...state, boosts: {...state.boosts, [payload.id]: payload }}
        }

        case SET_FREEZE: {
            return {...state, freezeEnergy: payload}
        }

        case SET_DAILIES: {
            return {...state, dailies: payload }
        }

        case SET_CURRENT_DAILY: {
            return {...state, currentDaily: payload}
        }

        case RESET_CURRENT_DAILY: {
            if (!state.dailies) return { ...state}
            return {...state, currentDaily: {
                profit: state.dailies[0].profit,
                unlockedAt: dayjs().add(24, 'hours').unix(),
                available: true,
                rewardLevel: state.dailies[0].rewardLevel
            }}
        }

        default:
            return state;
    }
}

export const getDispatchObject = (type: string, payload: any) => {
    return {type, payload};
}