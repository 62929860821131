import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MODAL_CONFIRM_FEES, SNACKBAR_ERROR, SNACKBAR_SUCCESS} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import {fetchData} from "../utils/api";
import useSnackbar from '../hooks/useSnackbar';

interface ModalParams {
  fees: string
  addr: string | null
  balance: number
  cbcAddress: string | null
}

const ConfirmFeesModal: FC = () => {

    const { t } = useTranslation();

    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const params = useMemo<ModalParams>(() => {
      return {
        fees: (activeModalParams && activeModalParams['fee']) ?? 0,
        addr: (activeModalParams && activeModalParams['solanaAddress']) ?? null,
        balance: (activeModalParams && activeModalParams['balance']) ?? 0,
        cbcAddress: (activeModalParams && activeModalParams['cbcAddress']) ?? null
      }
    }, [activeModalParams])
    const {setActiveSnackbar} = useSnackbar();

    const tryWithdrawal = async () => {
       try {
          const response = await fetchData('/wallets/withdraw');
          if (response.error || !response.result) {
              setActiveSnackbar(SNACKBAR_ERROR, { error: t('withdrawError') })
              return
          } 
          else if (response.result) {
              setActiveSnackbar(SNACKBAR_SUCCESS, { message: t('withdrawSuccess') })
          }

        } catch {
            setActiveSnackbar(SNACKBAR_ERROR, { error: t('withdrawError') })
        }
    }

    return (
        <Modal
            isOpen={activeModal === MODAL_CONFIRM_FEES}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={48} height={48} className='object-contain rounded-xl' src={require(`../assets/icons/currency/cbc.png`)} alt="" />
                              <div className='text-xl font-semibold text-center mt-4'>
                                {t('feesConfirmTitle')}
                              </div>
                            </div>
                            <div className='text-green-500 text-sm/4 text-center mt-1.5 break-all'>
                              {t('feesAddress') + (params?.addr ?? 'null')}
                            </div>
                            {
                              params && params.cbcAddress ? 
                              <div className='text-green-500 text-sm/4 text-center mt-1.5 break-all'>
                                {t('cbcAddress') + params.cbcAddress}
                              </div> :
                              <div className='text-red-500 text-sm/4 text-center mt-1.5'>
                                {t('cbcAddressDoesntExist')}
                              </div>
                            }
                            <div className='text-base font-semibold text-center mt-1.5'>
                              {t('feesAmount', { count: (params?.balance ?? 0), count_2: (params?.fees ?? 0) })}
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-3 text-lg'
                                onClick={onClose}
                            >
                                {t('boostsConfirmCancelButton')}
                            </Button>
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-3 text-lg'
                                onClick={tryWithdrawal}
                            >
                                {t('boostsConfirmButton')}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default ConfirmFeesModal;