import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MODAL_DEPOSIT_ADDRESS, SNACKBAR_ERROR} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import WoodRocket from '../assets/icons/currency/rocket/Wood';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultStateType, SET_CBC_ADDRESS, SET_CBC_TIMEOUT, getDispatchObject } from '../store/reducer';
import { formatNumberWithSpaces } from '../utils/mathUtils';
import i18n from '../i18n';
import { copyText, delay, getBonusTypes, getTimeLeft, taskMapCodes } from '../utils/utils';
import { fetchData } from '../utils/api';
import useSnackbar from '../hooks/useSnackbar';
import dayjs from 'dayjs';
import Icon24Copy from '../assets/icons/Icon24Copy';

const DepositAddressModal: FC = () => {

    const { t, i18n } = useTranslation();

    const {activeModal, setActiveModal, activeModalParams} = useModal()

    const dispatch = useDispatch()
    const cbcAddr = useSelector((s: DefaultStateType) => s.cbcAddress)
    const cbcTimeout = useSelector((s: DefaultStateType) => s.cbcAddressTimeout)

    const [timeLeft, setTimeLeft] = useState<string | null>(cbcTimeout ? getTimeLeft(cbcTimeout, 'minutes') : null)

    const [showError, setShowError] = useState(false)
    
    const {setActiveSnackbar} = useSnackbar()


        useEffect(() => {
        
            const interval = setInterval(() => {
        
                if (!cbcAddr || !cbcTimeout) {
                    if (timeLeft !== null) setTimeLeft(null)
                } else {
                  if (dayjs().unix() > cbcTimeout) {
                    dispatch(getDispatchObject(SET_CBC_ADDRESS, null))
                    dispatch(getDispatchObject(SET_CBC_TIMEOUT, null))
                  } else {
                    setTimeLeft(getTimeLeft(cbcTimeout, 'minutes'))
                  }
                }
    
            }, 1000);
        
            return () => clearInterval(interval);
        }, [timeLeft, cbcTimeout]);
  
    const getCbcAddress = async () => {
      setShowError(false)
      if (cbcAddr) return
      
      const response = await fetchData('/ttc/income_transfer')

      if (response.result === null || !response.result) {
        setActiveSnackbar(SNACKBAR_ERROR, { error: t('depositsLimit') })
        setShowError(true)
        return
      }

      const result = response.result

      copyText(result.cbcWallet)
      dispatch(getDispatchObject(SET_CBC_ADDRESS, result.cbcWallet))
      dispatch(getDispatchObject(SET_CBC_TIMEOUT, dayjs().add(10, 'minute').unix()))

    }

    const buttonText = useMemo(() => {
      return cbcAddr ? 'depositCopyAddress' : 'depositGetAddress'
    }, [cbcAddr])

    const openAction = async () => {
      if (cbcAddr) {
        copyText(cbcAddr)
      } else {
        getCbcAddress()
      }
    }

    const closeModal = () => {
      setShowError(false)
      setActiveModal(null)
    }

    return (
        <Modal
            isOpen={activeModal === MODAL_DEPOSIT_ADDRESS}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => closeModal()}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6 py-0">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain rounded-xl' src={require(`../assets/images/boosts/pig.png`)} alt="" />
                              <div className='text-lg font-semibold text-center mt-5'>
                                {t('depositTitle')}
                              </div>
                              <p className='text-sm/4 text-center mt-1.5'>
                                {t('depositDescription')}
                              </p>
                              {
                                cbcAddr ? 
                                <p className='text-green-500 text-sm/4 text-center mt-1.5 break-all'>
                                  {t('depositAddress') + cbcAddr}
                                </p> : null
                              }
                              {
                                cbcTimeout && timeLeft ?
                                <div className='bg-white/10 mt-1.5 px-1.5 text-base font-semibold rounded-xl tracking-wider'>
                                  {timeLeft}
                                </div> : null
                              }
                              {
                                showError  ? 
                                <div className="text-red-500 text-lg font-normal mt-4 text-center">
                                  {t('depositsLimit')}
                                </div> : null
                              }
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          <Button
                              fullWidth
                              className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                              onPress={openAction}
                          >
                            { cbcAddr ? 
                              <Icon24Copy /> : null
                            }
                            { t(buttonText) }
                          </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default DepositAddressModal;