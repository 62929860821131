import React, {FC, useEffect} from 'react';
import Tab from "./components/Tab/Tab";
import Img from "../../../components/Img/Img";
import Tabbar from "./components/Tabbar/Tabbar";
import {useNavigate, useLocation} from "react-router-dom";
import {ROUTE_BALANCE, ROUTE_FRIENDS_BONUS, ROUTE_ONBOARDING, ROUTE_HOME, ROUTE_FRIENDS, ROUTE_STATISTICS, ROUTE_BUILDINGS, ROUTE_TASKS, ROUTE_LEAGUE, ROUTE_BOOSTS, ROUTE_PRIVATE_SALE, ROUTE_PUBLIC_SALE, ROUTE_GIVEAWAY, ROUTE_PROFILE, ROUTE_PREMIUM, ROUTE_LOOT, ROUTE_ACHIEVEMENTS, ROUTE_MINING} from "../../../routes";
import {useTranslation} from "react-i18next";
import RouteIcon from './components/RouteIcon/RouteIcon';

interface NavigationProps {

}

// @ts-ignore
const tg = window["Telegram"]['WebApp'];

const Navigation: FC<NavigationProps> = ({}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    useEffect(() => {
        if (tg.initDataUnsafe.start_param) {
            if (tg.initDataUnsafe.start_param.includes('drawing')) {
                navigate(ROUTE_GIVEAWAY)
            }
            if (tg.initDataUnsafe.start_param.includes('mining')) {
                navigate(ROUTE_MINING)
            }
        }
    }, [])

    return (
        location.pathname !== ROUTE_ONBOARDING && location.pathname !== ROUTE_GIVEAWAY ?
            <Tabbar>
                {/* <Tab
                    icon={<Img radius={0} src={require('../../../assets/images/coins/coin-wood.png')} />}
                    name={t('navbarTapTap')}
                    active={[ROUTE_LEAGUE, ROUTE_BOOSTS, ROUTE_LOOT, ROUTE_MINING].includes(location.pathname)}
                    onClick={() => navigate(ROUTE_HOME)}
                /> */}
                {/* <Tab
                    icon={<RouteIcon name='builds' active={location.pathname === ROUTE_BUILDINGS} />}
                    name={t('navbarBuildings')}
                    active={location.pathname === ROUTE_BUILDINGS}
                    onClick={() => navigate(ROUTE_BUILDINGS)}
                /> */}
                <Tab
                    icon={<RouteIcon name='profile' active={[ROUTE_BALANCE, ROUTE_PROFILE, ROUTE_PRIVATE_SALE, ROUTE_PUBLIC_SALE, ROUTE_PREMIUM, ROUTE_ACHIEVEMENTS].includes(location.pathname)} />}
                    name={t('navbarProfile')}
                    active={[ROUTE_BALANCE, ROUTE_PROFILE, ROUTE_PRIVATE_SALE, ROUTE_PUBLIC_SALE, ROUTE_PREMIUM, ROUTE_ACHIEVEMENTS].includes(location.pathname)}
                    onClick={() => navigate(ROUTE_PROFILE)}
                />
                <Tab
                    icon={<RouteIcon name='users' active={location.pathname === ROUTE_FRIENDS|| location.pathname === ROUTE_FRIENDS_BONUS} />}
                    name={t('navbarFriends')}
                    active={location.pathname === ROUTE_FRIENDS|| location.pathname === ROUTE_FRIENDS_BONUS}
                    onClick={() => navigate(ROUTE_FRIENDS)}
                />
                <Tab
                    icon={<RouteIcon name='quests' active={location.pathname === ROUTE_TASKS} />}
                    name={t('navbarTasks')}
                    active={location.pathname === ROUTE_TASKS}
                    onClick={() => navigate(ROUTE_TASKS)}
                />
            </Tabbar> 
        : null
    );
};

export default Navigation;